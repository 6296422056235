import React, { FC } from 'react';
import classNames from 'classnames';
import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { BADTable } from '@bad/components';
import { routes } from '../../../shared-interface';
import { LicensesType, LicenseUsageDto, LicenseWithStatsDto, UsageDto } from '../../../api/models/licenses';

import styles from './licenses-table.module.scss';
import { LicensesChart } from './licenses-chart';
import { isEmbedded } from '../../../common';

const countTotal = (data: UsageDto[], propName: 'available' | 'used'): number => {
  let result = 0;
  if (data.length !== 0) {
    data.forEach((usage) => {
      let value = usage[propName];
      if (value === undefined) {
        result = Infinity;
        return;
      }
      result = result += value;
    });
  }
  return result;
};

export const countGlobal = (data: LicenseWithStatsDto[], propName: 'available' | 'used') => {
  let result = 0;

  data.forEach(({ usage }) => {
    // @ts-ignore
    const stats: UsageDto[] = [usage.regular, usage.trueUp, usage.virtual].filter(Boolean);
    result += countTotal(stats, propName);
  });
  return result;
};

export const LicensesTable: FC<{
  licensesType: LicensesType;
  data: LicenseWithStatsDto[];
  className?: string;
}> = ({ licensesType, data, className }) => {
  const numberCellClassName = (el: number, isBold: boolean = false, isRatio: boolean = false) =>
    classNames([styles.numberCell, !isEmbedded() && isBold && 'wt-h3', isRatio && el === 100 && styles.fullNumberCell, el === 0 && styles.emptyNumberCell]);

  const renderNumberCell = (data: UsageDto[], propName: 'available' | 'used' | 'ratio') => {
    return data.map((el, i) => {
      let value = propName === 'ratio' ? 0 : el[propName] ?? Infinity;

      if ((propName === 'ratio' || propName === 'available') && el['available'] === undefined) {
        value = Infinity;
      } else if (propName === 'ratio' && el.available) {
        value = Math.round((el.used / el.available) * 100);
      }

      if (propName === 'ratio' && isFinite(value)) {
        if (value === 0 && el.used !== 0) value = 1;
        if (value === 100 && el.used !== (el.available ?? 1)) value = 99;
      }
      const isRatio = propName === 'ratio';
      return (
        <div key={JSON.stringify([value, i])} className={classNames(numberCellClassName(value, data.length === 1, isRatio), styles.versionsCell)}>
          {isFinite(value) ? value : isRatio ? '-' : 'Unlimited'}
          {isRatio && isFinite(value) && '%'}
        </div>
      );
    });
  };

  let columns = [
    {
      id: 'licensesInfo',
      Header: 'License Name',
      minWidth: 28,
      Cell: (value: Cell<LicenseWithStatsDto>) => {
        return (
          <div>
            <Link
              to={routes().admin.licenses.tabs.versions({ licenseId: value.row.original.id, licensesType })}
              className={`wt-link ${isEmbedded() ? styles.licensesInfoLinkEmbedded : styles.licensesInfoLink}`}
            >
              {value.row.original.name}
            </Link>
          </div>
        );
      },
    },
    {
      id: 'type',
      accessor: 'usage',
      Header: 'License Type',
      minWidth: 15,
      Cell: ({ value }: Cell<LicenseUsageDto>) => {
        const stats = [
          { name: 'Prepaid', ...value.regular },
          { name: 'Postpaid', ...value.trueUp },
          { name: 'Virtual', ...value.virtual },
        ].filter((el) => Object.keys(el).includes('used'));
        return stats.length === 1 ? (
          <div className={!isEmbedded() ? classNames('wt-h3', styles.h3) : ''}>{stats[0].name}</div>
        ) : (
          <>
            {!isEmbedded() && <div className={classNames('wt-h3', styles.h3)}>All</div>}
            {stats.map((el, i) => (
              <div key={JSON.stringify([el.name, i])} className={styles.versionsCell}>
                {el.name}
              </div>
            ))}
          </>
        );
      },
    },
    {
      id: 'addedLicenses',
      accessor: 'usage',
      Header: <div className={styles.numberCell}>Quantity</div>,
      minWidth: 12,
      Cell: ({ value }: Cell<LicenseUsageDto>) => {
        const stats = [value.regular, value.trueUp, value.virtual].filter(Boolean);
        const total = countTotal(stats, 'available');
        return (
          <>
            {!isEmbedded() && (stats.length > 1 || stats.length === 0) && <div className={numberCellClassName(total, true)}>{total}</div>}
            {renderNumberCell(stats, 'available')}
          </>
        );
      },
    },
    {
      id: 'usedLicenses',
      accessor: 'usage',
      Header: <div className={styles.numberCell}>In use</div>,
      minWidth: 11,
      Cell: ({ value }: Cell<LicenseUsageDto>) => {
        const stats = [value.regular, value.trueUp, value.virtual].filter(Boolean);
        const total = countTotal(stats, 'used');
        return (
          <>
            {!isEmbedded() && (stats.length > 1 || stats.length === 0) && <div className={numberCellClassName(total, true)}>{total}</div>}
            {renderNumberCell(stats, 'used')}
          </>
        );
      },
    },
    {
      id: 'usageRatio',
      accessor: 'usage',
      Header: <div className={styles.numberCell}>Usage Ratio</div>,
      minWidth: 10,
      Cell: ({ value }: Cell<LicenseUsageDto>) => {
        const stats = [value.regular, value.trueUp, value.virtual].filter(Boolean);
        let used = countTotal(stats, 'used');
        let available = countTotal(stats, 'available');
        let total = Math.round((used / available) * 100);
        return (
          <>
            {!isEmbedded() && (stats.length > 1 || stats.length === 0) && <div className={numberCellClassName(total, true)}>{total}%</div>}
            {renderNumberCell(stats, 'ratio')}
          </>
        );
      },
    },
  ];
  if (!isEmbedded()) {
    columns.push({
      id: 'chart',
      accessor: 'id',
      Header: '60-Day Usage Chart',
      minWidth: 20,
      Cell: ({ value }: Cell<LicenseUsageDto>) => <LicensesChart licenseId={value} />,
    });
  }
  // @ts-ignore
  return <BADTable className={classNames(className, 'wt-text-3', styles.table)} offsetTableSize="s" wide={true} tableData={data} columnsFn={() => columns} />;
};

import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from '@webteam/layout';
import { UsersTable } from '../../../../components/dashboard/users-table';
import { ErrorPage } from '../../../../components/common/error-page';
import api from '../../../../api/routes';
import classnames from 'classnames';
import { ExportButton } from '../../../../components/dashboard/export/export-button';
import { ExportAllData } from '../../../../components/dashboard/export/export-all';
import { useParams } from 'react-router-dom';
import { SuperscriptCount } from '../../../../components/common/used-counter/superscript-count';
import { isEmbedded } from '../../../../common';

export const UsersTab = () => {
  const { licenseId } = useParams();

  const usersTotal = api.users.useUsersTotal(licenseId);
  const fetchUsersTable = useCallback(({ pageIndex, sort, search }) => api.users.fetchUsersTable(pageIndex, sort, search, licenseId), [licenseId]);

  if (usersTotal.error || !usersTotal.data) return <ErrorPage message={usersTotal.error?.title} detail={usersTotal.error?.detail} />;

  return (
    <div className="wt-offset-top-24">
      <Row size="xs" alignItems="end">
        <Col span="inline">
          <Header total={usersTotal.data.total ?? 0} />
        </Col>
        <Col span="inline">
          <ExportLicensesByUser licenseId={licenseId} />
        </Col>
      </Row>
      <Row>
        <Col span="auto-fill" className={classnames(usersTotal.data.total === 0 && 'wt-offset-top-24')}>
          {usersTotal.data.total > 0 ? (
            <UsersTable className="wt-offset-top-24" fetchUsersTable={fetchUsersTable} />
          ) : licenseId === undefined ? (
            <>
              <span className="wt-text-1 wt-text-1_hardness_average">
                No one is using licenses from your License Vault right now. To invite users, send them the link to your License Vault and the{' '}
                <a className={classnames('wt-link')} href={isEmbedded() ? 'https://jb.gg/lv-op-activate-ide' : 'https://jb.gg/ls-activate-ide'}>
                  activation instructions.
                </a>
              </span>
            </>
          ) : (
            <span className="wt-text-1 wt-text-1_hardness_average">No one is using licenses at the moment.</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

const ExportLicensesByUser: FC<{ licenseId?: string }> = ({ licenseId = undefined }) => {
  const [isModalOpen, toggleModalState] = useState(false);

  return (
    <>
      <ExportButton onClick={() => toggleModalState(true)} title={'Export usage statistics'} />
      <ExportAllData
        title={'Export licenses used by users'}
        isOpen={isModalOpen}
        licenseId={licenseId}
        onRequestClose={() => {
          toggleModalState(false);
        }}
      />
    </>
  );
};

const Header: FC<{ total: number }> = ({ total }) => {
  return (
    <h2 className="wt-h2">
      {total === 0 ? (
        'No Active Users'
      ) : (
        <>
          Active Users <SuperscriptCount count={total} />
        </>
      )}
    </h2>
  );
};

import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Col, Row } from '@webteam/layout';
import { DownIcon, UpIcon } from '@webteam/icons';
import { DenialStatisticDto } from '../../../../api/models/server/tickets.denials/stats';
import LinkButton from '../../../../components/common/link-button';
import { countTotal, parsePercent } from '../../../../common';

import styles from '../../stats.module.scss';
import { DenialPeriodType } from '../../../../api/models/server/tickets.denials';

export const ProductTable: FC<{ period: DenialPeriodType; data: DenialStatisticDto[]; productSearch: (value: string) => void }> = ({ period, data, productSearch }) => {
  const [showMore, setShowMore] = useState(false);
  const maxProductRow = 5;
  const lgColWidth = 5;
  const smColWidth = 2;
  const mdColWidth = 4;
  return (
    <>
      <HeadTable period={period} lgColWidth={lgColWidth} mdColWidth={mdColWidth} smColWidth={smColWidth} />
      {data.length > 1 && (
        <div onClick={() => productSearch('')}>
          <TotalRow data={data} lgColWidth={lgColWidth} mdColWidth={mdColWidth} smColWidth={smColWidth} />
        </div>
      )}
      {data.map(
        ({ productName, denialCount, productVersion, previousTimeSpanDenialCount }: DenialStatisticDto, i: number) =>
          (i < maxProductRow || showMore) && (
            <div
              key={i}
              onClick={() => {
                productSearch(productName);
              }}
            >
              <Row className={styles.row} alignItems="baseline" size="s">
                <Col span={smColWidth} className={classNames(styles.numberCell, styles.boldText, 'wt-text-2', 'wt-text-2_hardness_primary')}>
                  {denialCount}
                </Col>
                <Col span={lgColWidth} className="wt-text-2">
                  <span className="wt-text-2_hardness_primary">{productName}</span> {productVersion}
                </Col>
                <PercentCell mdColWidth={mdColWidth} denialCount={denialCount} previousTimeSpanDenialCount={previousTimeSpanDenialCount} />
              </Row>
            </div>
          ),
      )}
      {data.length > maxProductRow && (
        <Row className="wt-offset-top-12" size="s">
          <Col span="auto-fill" offsetLeft={smColWidth}>
            {!showMore ? (
              <LinkButton className="wt-text-3 wt-text-3_hardness_primary" onClick={() => setShowMore(true)}>
                More {data.length - maxProductRow} products
              </LinkButton>
            ) : (
              <LinkButton className="wt-text-3 wt-text-3_hardness_primary" onClick={() => setShowMore(false)}>
                Hide
              </LinkButton>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const HeadTable: FC<{ period: DenialPeriodType; lgColWidth: number; mdColWidth: number; smColWidth: number }> = ({ period, mdColWidth, lgColWidth, smColWidth }) => (
  <Row alignItems="baseline" size="s">
    <Col span={smColWidth} className={classNames('wt-text-3', styles.numberCell)}>
      Denials
    </Col>
    <Col offsetLeft={lgColWidth} span={mdColWidth} className={classNames('wt-text-3', styles.numberCell)}>
      To Prev. Period
    </Col>
  </Row>
);

const TotalRow: FC<{ data: DenialStatisticDto[]; lgColWidth: number; mdColWidth: number; smColWidth: number }> = ({ data, mdColWidth, lgColWidth, smColWidth }) => {
  const totalDenialCount = countTotal(data.map((el) => el.denialCount));
  const totalPreviousTimeSpanDenialCount = countTotal(data.map((el) => el.previousTimeSpanDenialCount));
  return (
    <Row className={styles.row} alignItems="baseline" size="s">
      <Col span={smColWidth} className={classNames(styles.numberCell, styles.boldText, 'wt-text-2', 'wt-text-2_hardness_primary')}>
        {totalDenialCount}
      </Col>
      <Col span={lgColWidth} className="wt-text-2">
        <span className="wt-text-2_hardness_primary">Total</span>
      </Col>
      <PercentCell mdColWidth={mdColWidth} denialCount={totalDenialCount} previousTimeSpanDenialCount={totalPreviousTimeSpanDenialCount} />
    </Row>
  );
};

const PercentCell: FC<{ mdColWidth: number; denialCount: number; previousTimeSpanDenialCount: number }> = ({ mdColWidth, denialCount, previousTimeSpanDenialCount }) => (
  <Col span={mdColWidth} className={classNames(styles.numberCell, 'wt-text-2')}>
    {denialCount === previousTimeSpanDenialCount ? (
      <span className={classNames(styles.zeroPercentCell, 'wt-text-2_hardness_pale')}>0%</span>
    ) : denialCount > previousTimeSpanDenialCount ? (
      <div className={classNames(styles.increase, styles.percentCell)}>
        <span>{parsePercent(previousTimeSpanDenialCount, denialCount)}%</span>
        <UpIcon size="xs" />
      </div>
    ) : (
      <div className={classNames(styles.recession, styles.percentCell)}>
        <span>{parsePercent(denialCount, previousTimeSpanDenialCount)}%</span>
        <DownIcon size="xs" />
      </div>
    )}
  </Col>
);

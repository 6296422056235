import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';
import { BADPopup } from '@bad/components';
import api from '../../../api/routes';
import { ModeContext } from '../../../context';
import { LicenseServerMode } from '../../../api/models/server';
import { pluralize } from '../../../common';

import styles from '../../../scss-global/global.module.scss';
import { markPending } from '../../../common/pending-changes';
import { LicenseRevocationData } from '../../../api/models/licenses';

export const RevokeLicensesConfirmation: FC<{
  username: string;
  licenseTicketIDs: string[];
  licenseNames: string[];
  isOpen: boolean;
  onRequestClose: () => void;
  revalidate: () => void;
  revokeData: LicenseRevocationData;
}> = ({ username, licenseTicketIDs, isOpen, onRequestClose, revalidate, licenseNames, revokeData }) => {
  const [error] = useState<string>();

  const onSubmit = async (values: object, { setSubmitting }: FormikHelpers<object>) => {
    licenseTicketIDs.forEach((el) => {
      markPending(el, 60000);
    });
    await api.licenses
      .revokeLicenses(licenseTicketIDs)
      .then(() => {
        onRequestClose();
        revalidate();
      })
      .catch((error) => {
        setSubmitting(false);
        throw new Error(error.detail);
      });
  };
  return (
    <ModeContext.Consumer>
      {(value: LicenseServerMode) => (
        <BADPopup
          header={<ConfirmationHeader username={username} licenseNames={licenseNames} revokeData={revokeData} mode={value} tickets={licenseTicketIDs} />}
          size="s"
          buttonSize="m"
          alignButton="end"
          primaryActionLabel={<>Revoke</>}
          closeActionLabel={<>Cancel</>}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          formikConfig={{ initialValues: {}, onSubmit }}
        >
          {() => {
            let revokeDialogContent;
            if (value === LicenseServerMode.NotSet) {
              revokeDialogContent = <></>;
            } else if (value === LicenseServerMode.Floating) {
              revokeDialogContent = <FloatingConfirmationContent amount={licenseNames.length} />;
            } else if (revokeData.amountOfTrueps === licenseTicketIDs.length) {
              revokeDialogContent = <NonFloatingTrueUpConfirmationContent amount={revokeData.amountOfTrueps} />;
            } else {
              revokeDialogContent = <NonFloatingConfirmationContent revocationData={revokeData} />;
            }
            return (
              <>
                {revokeDialogContent}
                <br />
                {error && <span className={styles.errorMessage}>{error}</span>}
              </>
            );
          }}
        </BADPopup>
      )}
    </ModeContext.Consumer>
  );
};

const ConfirmationHeader: FC<{ username: string; licenseNames: string[]; revokeData: LicenseRevocationData; mode: LicenseServerMode; tickets: string[] }> = ({
  username,
  licenseNames,
  revokeData,
  mode,
  tickets,
}) => {
  if (mode === LicenseServerMode.NotSet) return <></>;
  if (mode === LicenseServerMode.Floating) {
    return <>{tickets.length > 1 ? `Revoke the ${tickets.length} licenses from ${username}?` : `Revoke the ${licenseNames[0] ?? ''} license from ${username}?`}</>;
  }
  const amountOfTrueups = revokeData.amountOfTrueps;
  const regularTickets = tickets.length - amountOfTrueups;
  let licenseAmountDescription = `${regularTickets} prepaid ${pluralize(regularTickets, 'license')}`;
  if (amountOfTrueups !== 0) {
    licenseAmountDescription = licenseAmountDescription.concat(` and ${amountOfTrueups} postpaid ${pluralize(amountOfTrueups, 'license')}`);
  }
  return <>{tickets.length > 1 ? `Revoke ${licenseAmountDescription} from ${username}?` : `Revoke the ${licenseNames[0] ?? ''} license from ${username}?`}</>;
};

const NonFloatingConfirmationContent: FC<{ revocationData: LicenseRevocationData }> = ({ revocationData }) => (
  <>
    <p>
      According to the terms of usage, you can revoke up to {revocationData.totalRevokes} regular licenses per month. This limit doesn’t apply to postpaid licenses and is reset on the first day of
      each month.
    </p>
    <br />
    <p>
      {' '}
      You are using{' '}
      <span className={styles.boldText}>
        {revocationData.expectedRevokeUsage} of the {revocationData.currentAvailableRevokes} remaining {pluralize(revocationData.currentAvailableRevokes, 'revocation')}
      </span>{' '}
    </p>
  </>
);

const NonFloatingTrueUpConfirmationContent: FC<{ amount: number }> = ({ amount }) => {
  return (
    <>
      <p>{amount === 1 ? `This is a postpaid license. This revocation won’t affect your monthly limit.` : `Revoking postpaid licenses doesn’t affect your monthly revocation limit.`}</p>
    </>
  );
};

const FloatingConfirmationContent: FC<{ amount: number }> = ({ amount }) => (
  <>
    <p>Revocation doesn’t prevent the user from obtaining new licenses.</p>
    <br />
    <p>
      {' '}
      If you need to limit access to licenses for this user, set up a rule before revoking their licenses.{' '}
      <a className="wt-link" target={'_blank'} href={'https://jb.gg/lv-rules'} rel={'noreferrer'}>
        Learn more about access rules
      </a>
    </p>
  </>
);

import React, { FC, useState } from 'react';
import { LoadingIcon, MoreIcon, UndoIcon } from '@webteam/icons';
import { List, ListItem } from '@webteam/list';
import { BADDropdown } from '@bad/components';
import { RevokeLicensesConfirmation } from '../revoke-licenses-confirmation';
import { pluralize } from '../../../common';
import { LicenseTicketDto } from '../../../api/models/users';
import api from '../../../api/routes';

import styles from './users-table.module.scss';

export const MenuDropdown: FC<{ username: string; licenseTickets: LicenseTicketDto[]; revalidate: () => void }> = ({ username, licenseTickets, revalidate }) => {
  const [isOpenDropdown, toggleOpenDropdown] = useState(false);
  const [isOpenModal, toggleOpenModal] = useState(false);
  const availableRevokeLicenses = api.licenses.useDescribeRevokes(licenseTickets);

  const licenseTicketIDs: string[] = licenseTickets.map((el) => el.id as string).filter((el) => el != null);
  const licenseNames = licenseTickets.map((el) => el.license.name);

  const loading = !availableRevokeLicenses.data && !availableRevokeLicenses.error;

  if (loading)
    return (
      <span>
        <LoadingIcon />
      </span>
    );

  if (availableRevokeLicenses.error || !availableRevokeLicenses.data) return <></>;

  //@ts-ignore
  const isAllowedRevoke = availableRevokeLicenses.data.expectedSuccess;

  const pluralizeLicense = pluralize(licenseNames.length, 'license');
  let content;
  if (isAllowedRevoke) {
    content = <>{`Revoke ${pluralizeLicense}`}</>;
  } else if (licenseTicketIDs.length === 1) {
    content = (
      <>
        <p>No Revocations Left</p>
        <p className="wt-text-3 wt-text-3_hardness_pale">The limit is reset on the first day of each month.</p>
      </>
    );
  } else {
    content = (
      <>
        <p>Not Enough Revocations Left</p>
        <p className="wt-text-3 wt-text-3_hardness_pale">Click on the user’s name to revoke them one by one.</p>
      </>
    );
  }
  return (
    <>
      <BADDropdown
        placement="bottom-end"
        onRequestClose={() => toggleOpenDropdown(false)}
        trigger={<MoreIcon className={styles.moreIcon} onClick={() => toggleOpenDropdown(!isOpenDropdown)} />}
        isOpen={isOpenDropdown}
        size="xs"
      >
        <div className={styles.dropdownWrapperXs}>
          <List>
            <ListItem className={styles.revokeIconAlign} disabled={!isAllowedRevoke} icon={<UndoIcon />} onClick={toggleOpenModal}>
              {content}
            </ListItem>
          </List>
        </div>
      </BADDropdown>
      <RevokeLicensesConfirmation
        username={username}
        licenseTicketIDs={licenseTicketIDs ?? []}
        isOpen={isOpenModal}
        onRequestClose={() => toggleOpenModal(false)}
        revalidate={() => {
          setTimeout(revalidate, 100);
        }}
        licenseNames={licenseNames}
        revokeData={availableRevokeLicenses.data}
      />
    </>
  );
};

import { FC } from 'react';
import { isEmbedded } from '../../../common';

import styles from './common-dashboard.module.scss';

export const EmbeddedConditionSuperscriptCount: FC<{ count: number }> = ({ count }) => {
  return <sup className={styles.sup}>{isEmbedded() ? ' ' : count}</sup>;
};

export const SuperscriptCount: FC<{ count: number }> = ({ count }) => {
  return <sup className={styles.sup}>{count}</sup>;
};

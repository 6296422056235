import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationPopup } from '../../../../../components/common/confirmation-popup';
import { AccessRuleDto } from '../../../../../api/models/server/rules';
import api from '../../../../../api/routes';

import styles from './rules-popups.module.scss';

export const DeleteRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const { id } = useParams() as { id: string };
  const rules = api.server.rules.useRules();
  const item = rules.data?.find((el) => el.id === +id);
  if (item)
    return (
      <ConfirmationPopup
        successAction={rules.mutate}
        title={`Remove the “${item.name}” Rule?`}
        content={
          <>
            It cannot be undone.
            <br />
            All mentioned users and groups will be able to use all licenses without restrictions.
          </>
        }
        action={() => api.server.rules.deleteRule(item.id)}
        actionButtonName="Remove Rule"
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};

export const SwitchRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const rules = api.server.rules.useRules();
  const { id } = useParams() as { id: string };
  const item: AccessRuleDto | undefined = rules.data?.find((el) => el.id === +id);
  const ruleSettings = api.server.rules.useRuleSettings();
  if (item)
    return (
      <ConfirmationPopup
        content={
          <>
            Users and groups mentioned in <span className={styles.boldText}>{item.name}</span> will{' '}
            {ruleSettings.data?.permitUnruledUsers ? (
              <>
                have access to all <br /> licenses unless it’s restricted by other rules.
              </>
            ) : (
              <>
                lose access to <br /> licenses unless it’s allowed by other rules.'
              </>
            )}
          </>
        }
        successAction={rules.mutate}
        title={`${item?.enabled ? 'Disable' : 'Enable'} rule?`}
        action={() =>
          api.server.rules.updateRule(item.id, {
            enabled: !item.enabled,
            name: item.name,
            access: item.access,
            subjectsToAdd: [],
            subjectsToDelete: [],
            productsToAdd: [],
            productsToDelete: [],
          })
        }
        actionButtonName={`${item?.enabled ? 'Disable' : 'Enable'}`}
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};

export const SettingsRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const ruleSettings = api.server.rules.useRuleSettings();
  return (
    <ConfirmationPopup
      successAction={ruleSettings.mutate}
      content={
        <>
          {ruleSettings.data?.permitUnruledUsers
            ? 'Users won’t be able to obtain licenses unless you create a rule that allows them access.'
            : 'Users will be able to obtain any licenses unless you create a rule that restricts their access.'}
        </>
      }
      title={`${ruleSettings.data?.permitUnruledUsers ? 'Restrict' : 'Allow'} access for users not mentioned in the rules?`}
      action={() => api.server.rules.setRuleSettings({ permitUnruledUsers: !ruleSettings.data?.permitUnruledUsers })}
      actionButtonName={ruleSettings.data?.permitUnruledUsers ? 'Restrict access' : 'Allow access'}
      onRequestClose={onRequestClose}
    />
  );
};

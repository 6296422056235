export interface LicenseDto {
  id: string;
  name: string;
}

export interface LicenseWithStatsDto extends LicenseDto {
  usage: LicenseUsageDto;
}

export interface LicenseUsageDto {
  regular?: UsageDto;
  trueUp?: UsageDto;
  virtual?: UsageDto;
}

export interface UsageDto {
  available?: number;
  used: number;
}

export enum LicensesType {
  Product = 'product',
  Plugin = 'plugin',
}

export interface TimedUsage {
  timestamp: string;
  total: number;
  used: number;
}

export enum LicenseUsageStatPeriod {
  TwentyFourHours = '24h',
  ThirtyDays = '30d',
  SixtyDays = '60d',
  Year = 'year',
}
export interface LicenseRevocationData {
  expectedSuccess: boolean;
  expectedRevokeUsage: number;
  currentAvailableRevokes: number;
  totalRevokes: number;
  amountOfTrueps: number;
}

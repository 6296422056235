import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Cell } from 'react-table';
import { BADTable } from '@bad/components';
import { getTimezone, parseDate } from '../../../../common';
import { TicketDenialEventDto, TicketDenialReason } from '../../../../api/models/server/tickets.denials/events';
import { DenialPeriodType } from '../../../../api/models/server/tickets.denials';

import styles from '../../stats.module.scss';
import { FetchTableData } from '@bad/components/dist/bad-table/data';

import { parsePeriod } from './index';
import { EmbeddedConditionSuperscriptCount } from '../../../../components/common/used-counter/superscript-count';

enum EventsTableColumnId {
  Timestamp = 'timestamp',
  Username = 'username',
  Product = 'product',
  Reason = 'reason',
  Hostname = 'hostname',
  Ip = 'ip',
}

export const EventsTable: FC<{
  period: DenialPeriodType;
  fetchTableData: FetchTableData<TicketDenialEventDto, EventsTableColumnId>;
  totalDenialCount: number;
  className?: string;
  search?: string;
  resetSearch?: () => void;
}> = ({ period, fetchTableData, totalDenialCount, className, search }) => {
  const setSearch = useRef<Dispatch<SetStateAction<string>>>();

  useEffect(() => {
    setSearch.current?.(search ?? '');
  }, [search, setSearch]);
  return (
    <>
      <DenialAnnounce count={totalDenialCount} period={period} />
      <BADTable<TicketDenialEventDto, any, EventsTableColumnId>
        className={classNames(className, styles.resetTableRowOffset, styles.tablePadding, 'wt-text-3', 'wt-offset-top-24')}
        offsetTableSize="s"
        size="s"
        tableData={fetchTableData}
        tableDataFnArgs={period}
        showSearch={true}
        setSearchRef={setSearch}
        searchPlaceholder="Search by username or product"
        columnsFn={() => [
          {
            id: EventsTableColumnId.Timestamp,
            accessor: 'timestamp',
            Header: () => <span>Date (GMT{getTimezone()})</span>,
            minWidth: 15,
            Cell: ({ value: timestamp }: Cell<TicketDenialEventDto>) => parseDate(timestamp),
          },
          {
            id: EventsTableColumnId.Username,
            accessor: 'username',
            Header: 'Username',
            minWidth: 15,
          },
          {
            id: EventsTableColumnId.Product,
            Header: 'Product Name',
            minWidth: 18,
            Cell: ({
              row: {
                original: { productDisplayName, productVersion },
              },
            }: Cell<TicketDenialEventDto>) => {
              return (
                <>
                  {productDisplayName} <span className="wt-text-2_hardness_average"> {productVersion}</span>
                </>
              );
            },
          },
          {
            id: EventsTableColumnId.Reason,
            accessor: 'denialReason',
            Header: 'Denial Reason',
            minWidth: 17,
            Cell: ({ value: reason }: Cell<TicketDenialEventDto>) => {
              return <>{parseDenialReason(reason)}</>;
            },
          },
          {
            id: EventsTableColumnId.Hostname,
            accessor: 'hostname',
            Header: 'Hostname',
            minWidth: 15,
          },
          {
            id: EventsTableColumnId.Ip,
            accessor: 'ip',
            Header: 'IP',
            minWidth: 10,
          },
        ]}
      />
    </>
  );
};

export const DenialAnnounce: FC<{
  count: number;
  period: DenialPeriodType;
}> = ({ count, period }) => {
  return (
    <h3 className="wt-h3 wt-offset-top-24">
      Denials in the past {parsePeriod(period)}
    </h3>
  );
};

export const parseDenialReason = (reason: TicketDenialReason): string => {
  switch (reason) {
    case TicketDenialReason.CANCELLED:
      return 'No available licenses';
    case TicketDenialReason.FORBIDDEN:
      return 'Forbidden by rule';
    case TicketDenialReason.UNAUTHORIZED:
      return 'User not authorized by JetBrains Hub';
  }
};

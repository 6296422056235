import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../../api/routes';
import { ConfirmationPopup } from '../../../../../components/common/confirmation-popup';

export const DeletePrioritiesPopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const { id } = useParams() as { id: string };
  const priorities = api.server.rules.priorities.usePriorities();
  const item = priorities.data?.find((el) => el.id === +id);
  if (item)
    return (
      <ConfirmationPopup
        successAction={priorities.mutate}
        title={`Remove ${item.subject.name} from the priority list?`}
        action={() => api.server.rules.priorities.deletePriorities(id)}
        actionButtonName={`Remove`}
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};

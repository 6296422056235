import React, { FC, useState } from 'react';
import { Cell } from 'react-table';
import classNames from 'classnames';
import { List, ListItem } from '@webteam/list';
import { MoreIcon, PenIcon, PersonIcon, TeamIcon, TrashIcon } from '@webteam/icons';
import { BADButton, BADDropdown, BADTable } from '@bad/components';
import { BasicRuleSubject, RuleSubjectType } from '../../../../api/models/server/rules';
import { Priorities } from '../../../../api/models/server/rules/priorities';

import styles from './priorities.module.scss';

export const PrioritiesTable: FC<{ data: Priorities[]; onEditPriorities: () => void; onDeletePriorities: (id: number) => void }> = ({ data, onEditPriorities, onDeletePriorities }) => (
  <BADTable
    offsetTableSize="xs"
    size="s"
    clientSideSearchAndSortFn={({ data, search }) => {
      if (!search) return data;
      return data.filter((el) => el.subject.name.toLowerCase().includes(search.toLowerCase()));
    }}
    pageSize={100}
    showSearch={true}
    tableData={data}
    searchPlaceholder="Search by username"
    hideBottomTablePagination={true}
    className={classNames(styles.menuIconAlign, styles.tableAlign, styles.fixInputWidth, 'wt-text-3')}
    columnsFn={() => [
      {
        id: 'type',
        Header: 'Type',
        accessor: 'subject',
        minWidth: 5,
        Cell: ({ value }: Cell<{ subject: BasicRuleSubject }>) => {
          const Icon = value.subjectType === RuleSubjectType.Group ? TeamIcon : PersonIcon;
          return <Icon className={styles.groupOrUserIcon} />;
        },
      },
      {
        id: 'userOrGroup',
        Header: 'User or Group',
        accessor: 'subject',
        minWidth: 90,
        Cell: ({ value }: Cell<{ subject: BasicRuleSubject }>) => <span className={classNames('wt-text-2 wt-text-2_hardness_primary', styles.breakName)}>{value.name}</span>,
      },
      {
        id: 'menu',
        Header: '',
        accessor: 'id',
        minWidth: 5,
        Cell: ({ value }: Cell<{ id: string }>) => (
          <Menu
            onEditPriorities={onEditPriorities}
            onDeletePriorities={() => {
              onDeletePriorities(value);
            }}
          />
        ),
      },
    ]}
  />
);

const Menu: FC<{ onEditPriorities: () => void; onDeletePriorities: () => void }> = ({ onEditPriorities, onDeletePriorities }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <BADDropdown
      placement="bottom-end"
      size="xs"
      onRequestClose={() => setOpen(false)}
      trigger={<BADButton onClick={() => setOpen(!isOpen)} size="s" mode="nude" icon={<MoreIcon className={styles.paleIcon} />} />}
      isOpen={isOpen}
    >
      <List>
        <ListItem icon={<PenIcon />} onClick={onEditPriorities}>
          Edit
        </ListItem>
        <ListItem icon={<TrashIcon />} onClick={onDeletePriorities}>
          Remove
        </ListItem>
      </List>
    </BADDropdown>
  );
};
